import React, { useState } from 'react'
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import { Flame, ArrowRight, Mail } from "lucide-react"

export default function App() {
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [email, setEmail] = useState('')

  const handleButtonClick = (content: string) => {
    setModalContent(content)
    setShowModal(true)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    alert(`Thank you for subscribing with ${email}! We'll keep you updated.`)
    setEmail('')
  }

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <header className="px-4 lg:px-6 h-16 flex items-center">
        <div className="flex items-center space-x-2">
          <Flame className="h-6 w-6 text-orange-500" />
          <span className="text-xl font-bold">FireGen AI</span>
        </div>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  FireGen AI — Powering Autonomous Intelligence
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl">
                  Building modular frameworks for autonomous agents, with a focus on accessibility and production-ready AI solutions for businesses.
                </p>
              </div>
              <div className="space-x-4">
                <Button onClick={() => handleButtonClick('Learn More content coming soon!')}>Learn More</Button>
                <Button variant="outline" onClick={() => handleButtonClick('Contact us at leonardo@firegen.ai')}>Contact Us</Button>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container px-4 md:px-6">
            <div className="grid gap-10 px-10 md:gap-16 lg:grid-cols-2">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold">Modular AI Frameworks</h2>
                <p className="text-gray-500">
                  Our cutting-edge modular frameworks enable rapid development and deployment of autonomous agents, tailored to your specific business needs.
                </p>
              </div>
              <div className="space-y-4">
                <h2 className="text-3xl font-bold">Production-Ready Solutions</h2>
                <p className="text-gray-500">
                  FireGen AI delivers scalable, robust AI solutions that are ready for real-world applications, helping businesses stay ahead in the AI revolution.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
                  Coming Soon
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl">
                  We're working on exciting new AI solutions that will revolutionize the way businesses operate. Stay tuned for updates!
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <form onSubmit={handleSubmit} className="flex space-x-2">
                  <Input
                    className="max-w-lg flex-1"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Button type="submit">
                    Subscribe
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </form>
                <p className="text-xs text-gray-500">
                  Stay informed about our latest developments and releases.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
                  Get in Touch
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl">
                  Interested in learning more about FireGen AI? We'd love to hear from you!
                </p>
              </div>
              <a
                href="mailto:leonardo@firegen.ai"
                className="inline-flex items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow transition-colors hover:bg-gray-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950"
              >
                <Mail className="mr-2 h-4 w-4" />
                Contact Us
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500">
          © 2024 FireGen AI. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs hover:underline underline-offset-4" href="#" onClick={(e) => { e.preventDefault(); handleButtonClick('Terms of Service coming soon!'); }}>
            Terms of Service
          </a>
          <a className="text-xs hover:underline underline-offset-4" href="#" onClick={(e) => { e.preventDefault(); handleButtonClick('Privacy Policy coming soon!'); }}>
            Privacy
          </a>
        </nav>
      </footer>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <p>{modalContent}</p>
            <Button className="mt-4" onClick={() => setShowModal(false)}>Close</Button>
          </div>
        </div>
      )}
    </div>
  )
}